<template>
  <aside class="page__sidebar">
    <div class="sidebar__header">
      <h1 class="sidebar__title">Minhas coletas</h1>
      <h5 class="sidebar__subtitle">Gerencie seus produtos</h5>
    </div>

    <div class="sidebar__nav">
      <router-link
        class="sidebar__nav--anchor"
        active-class="current"
        to="/coletas-solicitadas"
        title="Solicitadas"
      >
        <i class="icon icon-scheduled"></i>
        Aguardando coletor
      </router-link>

      <router-link
        class="sidebar__nav--anchor"
        active-class="current"
        to="/coletas-agendadas"
        title="Agendadas"
      >
      <i class="icon icon-requested"></i>
        Agendadas
      </router-link>
      <router-link
        class="sidebar__nav--anchor"
        active-class="current"
        to="/armario"
        title="Todos os itens"
      >
        <i class="icon icon-closet"></i>
        Coletados
      </router-link>
    </div>
  </aside>
</template>

<script>
export default {}
</script>

<style></style>
